<template>
  <div class="content new-template-content">
    <PageHeader title="RCS" :subtitle="$t('template-component.new')" />
    <section class="page-content container-fluid mb-3">
      <div class="row">
        <!-- Content col -->
        <div class="col-8 left-content-column">
          <InputComponent v-model="template.name" :label="$tc('app.name')" :rules="['max:255']"></InputComponent>
          <!-- Header -->
          <label class="mb-4">Escolha o tipo de mensagem:</label>
          <div class="header-wrapper">
            <CardComponent :border-variant="template.type === 'TEXT' ? 'info' : ''">
              <template #header>
                <img class="mt-3" src="@/assets/skeleton-text.svg" alt="Skeleton Text" />
                <button class="btn btn-choose-type stretched-link" @click="template.type = 'TEXT'">Texto
                </button>
              </template>
            </CardComponent>
            <CardComponent :border-variant="template.type === 'FILE' ? 'info' : ''">
              <template #header>
                <div class="icon-wrapper mt-3">
                  <span class="material-symbols-outlined">photo</span>
                </div>
                <button class="btn btn-choose-type stretched-link" @click="template.type = 'FILE'">Arquivo</button>
              </template>
            </CardComponent>
            <CardComponent :border-variant="template.type === 'CAROUSELS' ? 'info' : ''">
              <template #header>
                <div class="icon-wrapper mt-3">
                  <span class="material-symbols-outlined">view_carousel</span>
                </div>
                <button class="btn btn-choose-type stretched-link"
                  @click="template.type = 'CAROUSELS'">Carrossel</button>
              </template>
            </CardComponent>
            <CardComponent :border-variant="template.type === 'CARD' ? 'info' : ''">
              <template #header>
                <div class="icon-wrapper mt-3">
                  <span class="material-symbols-outlined">pages</span>
                </div>
                <button class="btn btn-choose-type stretched-link" @click="template.type = 'CARD'">Card</button>
              </template>
            </CardComponent>
          </div>
          <!-- Body -->
          <div class="form-group row" v-if="template.type != 'FILE' && template.type != 'CARD'">
            <hr class="hr-divider">
          </div>
          <div class="form-group row"
            v-if="template.type != 'FILE' && template.type != 'CARD' && template.type != 'CAROUSELS'"
            style="margin-bottom: 0;">
            <div class="col-1" style="max-width: 5%;">
              <span class="material-symbols-outlined info-icons info-var-tip">data_object</span>
            </div>
            <div class="col-9 ">
              <label>Variáveis do sistema que podem ser usadas: </label>
            </div>
          </div>
          <div class="form-group row"
            v-if="template.type != 'FILE' && template.type != 'CARD' && template.type != 'CAROUSELS'">
            <div class="col-1" style="max-width: 5%;">
            </div>
            <div class="col-9 info-var-tip-text">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-3">
                  <span class="var-name-span">{first_name}</span>
                </div>
                <div class="col-3">
                  <span class="var-name-span">{last_name}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <span class="var-name-span">{mobile_number}</span>
                </div>
                <div class="col-3">
                  <span class="var-name-span">{email}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group contents-form" v-if="template.type == 'TEXT'">
            <div class="row">
              <div class="col">
                <label for="sms-body">{{
                  $t('sms.send-msg.sms-input-component.title')
                  }}:
                </label>
                <!-- Conteúdo -->
              </div>
              <div class="col text-right">
                <b-dropdown class="dropdown-badge mr-2" v-if="variables.length" variant="transparent p-0">
                  <template #button-content>
                    <span class="badge btn-yup-purple">{{
                      $t(
                        'sms.send-msg.sms-input-component.lbl-variables',
                      )
                    }}</span>
                  </template>
                  <b-dd-item v-for="variable in variables" :key="variable.value" @click="addVariable(variable)">
                    {{ variable.name }}
                  </b-dd-item>
                </b-dropdown>
                <Emojis v-if="template.type === 'TEXT'" @select="addEmoji" title="Emoji" />
              </div>
            </div>
            <textarea v-if="template.type === 'TEXT'" class="form-control text-area-sms" rows="6" maxlength="612"
              id="sms-body" v-model="template.body.plain.text"></textarea>
          </div>
          <div class="form-group mb-4" v-if="template.type == 'FILE'">
            <label>Adicionar arquivo: </label>
            <p>Faça upload de arquivos nos diferentes formatod pdf, png, jpg, etc.</p>
            <div class="content-upload relative" ref="dropz">
              <div class="content-inside">
                <span class="material-symbols-outlined">upload_file</span>
                <h4>Adicionar arquivos pdf, png, jpg ou gif ou arraste e solte</h4>
              </div>
              <input type="file" ref="fileInput" @change="onFilePicked" />
            </div>
          </div>
          <div class="form-group mb-4 relative" v-if="template.type == 'FILE'">
            <input class="form-control" placeholder="Link" v-model="template.body.plain.url">
            <span class="material-symbols-outlined link-input">link</span>
          </div>
          <!--Card-->
          <div class="form-group row mb-4" v-if="template.type == 'CARD'">
            <div class="col-12">
              <label>Detalhe da mensagem: </label>
              <br>
              <div class="shadow-card rounded card-rich" style="width: 300px">
                <div class="row">
                  <div class="col-12">
                    <div class="card-rich-cover relative">
                      <img :src="template.body.card.url" id="card-rich-cover-tab" />
                      <ImageUploader @upload="(file) =>
                        (template.body.card.url = file.url)
                        ">
                        <i class="fas fa-edit card-edit-btn absolute"></i>
                      </ImageUploader>
                    </div>
                  </div>
                  <div class="col-12">
                    <label>Título</label>
                    <input class="form-control" v-model="template.body.card.title" />
                    <label>Descrição</label>
                    <textarea class="form-control" style="width: 100%; float: left"
                      v-model="template.body.card.description"></textarea>
                  </div>
                </div>
                <div v-for="(button, n) in template.body.card.buttons" :key="n" class="row">
                  <hr v-if="n > 0" />
                  <div class="col-md-12">
                    <div v-if="button.type === 'reply'" class="input-group mt-3">
                      <input placeholder="Texto" v-model="button.text" type="text" class="form-control"
                        @input="handleInput" />
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-icon" type="button"
                          @click="removeButton(template.body.card, n)">
                          <span class="material-icons edit_square_icon"> close </span>
                        </button>
                      </div>
                    </div>
                    <div v-if="button.type === 'url'" class="input-group mt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-icon-addon1"><i class="icon dripicons-web"></i></span>
                      </div>
                      <input placeholder="Link" v-model="button.url" type="text" class="form-control"
                        @input="handleInput" />
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-icon" type="button"
                          @click="removeButton(template.body.card, n)">
                          <span class="material-icons edit_square_icon"> close </span>
                        </button>
                      </div>
                    </div>
                    <div v-else-if="button.type === 'location'" class="input-group mt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-icon-addon1"><i
                            class="icon dripicons-location"></i></span>
                      </div>
                      <input type="text" class="form-control" placeholder="Localização..." v-model="button.address" />
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-icon" type="button"
                          @click="removeButton(template.body.card, n)">
                          <span class="material-icons edit_square_icon"> close </span>
                        </button>
                      </div>
                    </div>
                    <div v-if="button.type === 'event'">
                      <div class="input-group mt-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-icon-addon1"><i
                              class="icon dripicons-calendar"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Titulo do Evento" v-model="button.title" />
                        <div class="input-group-append">
                          <button class="btn btn-primary btn-icon" type="button"
                            @click="removeButton(template.body.card, n)">
                            <span class="material-icons edit_square_icon"> close </span>
                          </button>
                        </div>
                      </div>
                      <div class="input-group mt-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-icon-addon1"><i
                              class="icon dripicons-calendar"></i></span>
                        </div>
                        <date-range-picker class="form-control" :format="'YYYY-MM-DD hh:mm'" :options="calendarOptions"
                          @change="changeDate($event, button)" />
                      </div>
                    </div>
                    <div v-show="button.type === 'dial'" class="input-group mt-3">
                      <vue-tel-input v-model="button.phone_number" v-bind="phoneOptions"></vue-tel-input>
                      <div class="input-group-append">
                        <button class="btn btn-primary btn-icon" type="button"
                          @click="removeButton(template.body.card, n)">
                          <span class="material-icons edit_square_icon"> close </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 btn-actions">
                    <button class="btn-add-action">
                      <span class="material-symbols-outlined info-icons info-var-tip">flash_on</span> Adicionar ação
                    </button>
                    <button class="btn-add-action-hover">
                      <span class="material-symbols-outlined"
                        @click="addButton(template.body.card, 'dial')">phone</span>
                      <span class="material-symbols-outlined"
                        @click="addButton(template.body.card, 'reply')">text_fields</span>
                      <span class="material-symbols-outlined"
                        @click="addButton(template.body.card, 'url')">public</span>
                      <span class="material-symbols-outlined"
                        @click="addButton(template.body.card, 'location')">location_on</span>
                      <span class="material-symbols-outlined"
                        @click="addButton(template.body.card, 'event')">calendar_month</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End Card-->
          <!--CAROUSELS-->
          <div class="form-group row mb-4" v-if="template.type == 'CAROUSELS'">
            <div class="col-12">
              <div class="content-message">
                <label>Detalhe da mensagem: </label>
                <button class="btn btn-add-template" @click="addCard">
                  <span class="material-symbols-outlined">add_circle</span><span class="span-text">Novo cartão</span>
                </button>
              </div>
              <br>
              <div class="row flex-nowrap overflow-auto">
                <div class="col-4" v-for="(card, i) in template.body.carousel.cards" :key="i">
                  <div class="shadow-card rounded card-rich" style="width: 300px">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-rich-cover relative">
                          <img :src="card.image" id="card-rich-cover-tab" />
                          <ImageUploader @upload="(file) =>
                            (card.image = file.url)
                            ">
                            <i class="fas fa-edit card-edit-btn absolute c-pointer"></i>
                          </ImageUploader>
                          <span v-if="template.body.carousel.cards.length > 2"
                            class="c-pointer material-symbols-outlined remove-card-icon" @click="removeCard(i)">
                            close
                          </span>
                        </div>
                      </div>
                      <div class="col-12">
                        <label>Título</label>
                        <input class="form-control" v-model="card.title" />
                        <label>Descrição</label>
                        <textarea class="form-control" style="width: 100%; float: left"
                          v-model="card.description"></textarea>
                      </div>
                    </div>
                    <div v-for="(button, n) in card.buttons" :key="n" class="row">
                      <hr v-if="n > 0" />
                      <div class="col-md-12">
                        <div v-if="button.type === 'reply'" class="input-group mt-3">
                          <input placeholder="Texto" v-model="button.text" type="text" class="form-control"
                            @input="handleInput" />
                          <div class="input-group-append">
                            <button class="btn btn-primary btn-icon" type="button" @click="removeButton(card, n)">
                              <span class="material-icons edit_square_icon"> close </span>
                            </button>
                          </div>
                        </div>
                        <div v-if="button.type === 'url'" class="input-group mt-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-icon-addon1"><i
                                class="icon dripicons-web"></i></span>
                          </div>
                          <input placeholder="Link" v-model="button.url" type="text" class="form-control"
                            @input="handleInput" />
                          <div class="input-group-append">
                            <button class="btn btn-primary btn-icon" type="button" @click="removeButton(card, n)">
                              <span class="material-icons edit_square_icon"> close </span>
                            </button>
                          </div>
                        </div>
                        <div v-else-if="button.type === 'location'" class="input-group mt-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-icon-addon1"><i
                                class="icon dripicons-location"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Localização..."
                            v-model="button.address" />
                          <div class="input-group-append">
                            <button class="btn btn-primary btn-icon" type="button" @click="removeButton(card, n)">
                              <span class="material-icons edit_square_icon"> close </span>
                            </button>
                          </div>
                        </div>
                        <div v-if="button.type === 'event'">
                          <div class="input-group mt-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-icon-addon1"><i
                                  class="icon dripicons-calendar"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Titulo do Evento"
                              v-model="button.title" />
                            <div class="input-group-append">
                              <button class="btn btn-primary btn-icon" type="button" @click="removeButton(card, n)">
                                <span class="material-icons edit_square_icon"> close </span>
                              </button>
                            </div>
                          </div>
                          <div class="input-group mt-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-icon-addon1"><i
                                  class="icon dripicons-calendar"></i></span>
                            </div>
                            <date-range-picker class="form-control" :format="'YYYY-MM-DD hh:mm'"
                              :options="calendarOptions" @change="changeDate($event, button)" />
                          </div>
                        </div>
                        <div v-show="button.type === 'dial'" class="input-group mt-3">
                          <vue-tel-input v-model="button.phone_number" v-bind="phoneOptions"></vue-tel-input>
                          <div class="input-group-append">
                            <button class="btn btn-primary btn-icon" type="button" @click="removeButton(card, n)">
                              <span class="material-icons edit_square_icon"> close </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 btn-actions">
                        <button class="btn-add-action">
                          <span class="material-symbols-outlined info-icons info-var-tip">flash_on</span> Adicionar ação
                        </button>
                        <button class="btn-add-action-hover">
                          <span class="material-symbols-outlined" @click="addButton(card, 'dial')">phone</span>
                          <span class="material-symbols-outlined" @click="addButton(card, 'reply')">text_fields</span>
                          <span class="material-symbols-outlined" @click="addButton(card, 'url')">public</span>
                          <span class="material-symbols-outlined"
                            @click="addButton(card, 'location')">location_on</span>
                          <span class="material-symbols-outlined"
                            @click="addButton(card, 'event')">calendar_month</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End CAROUSELS-->
          <div class="row">
            <div class="col-sm-12">
              <hr>
            </div>
            <div class="col-sm-12">
              <div class="btn-wrapper">
                <br>
                <br>
                <br>
                <br>
                <button @click="saveTemplate()" class="btn btn-yup-purple ml-auto"
                  :disabled="isSending || !validTemplate"
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending, }">
                  {{ $t('app.save') }} template
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--/Content col -->
        <!-- Preview -->
        <div class="col">
          <div class="preview-wrapper">
            <TemplatePreview :template="template" />
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview -->
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import RCSService from '@/services/rcs.service';
import axios from '@/plugins/axios';
import moment from 'moment';
import ImageUploader from '@/components/ImageUploader.vue';
import TemplatePreview from '@/components/rcs/TemplatePreview.vue';
import { VueTelInput } from 'vue-tel-input';
import Emojis from '@/components/rebranding/Emojis.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import CardComponent from '@/components/CardComponent.vue';

const webApi = axios();
const bucketApi = axios('buckets3');

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    ImageUploader,
    TemplatePreview,
    VueTelInput,
    Emojis,
    InputComponent,
    CardComponent,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store?.state?.auth?.user;
    },
  },
  data() {
    return {
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      richCardBtn: null,
      isSending: false,
      validTemplate: true,
      file: {},
      template: {
        type: 'TEXT',
        name: '',
        body: {
          plain: {
            text: '',
            url: '',
          },
          card: {
            title: '',
            description: '',
            orientation: 'VERTICAL',
            url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
            size: 'TALL',
            alignment: 'LEFT',
            buttons: [],
          },
          carousel: {
            cards: [
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
            ],
          },
        },
      },
      phoneOptions: {
        autoFormat: true,
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      variables: [],
      calendarOptions: {
        timePicker24Hour: true,
        timePicker: true,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
    };
  },
  created() {
    if (this.$route.params.template) {
      this.template = this.$route.params.template;
    }
  },
  methods: {
    addEmoji(emoji) {
      this.template.body.plain.text = `${this.template.body.plain.text}${emoji}`;
    },
    addVariable(variable) {
      this.template.body.plain.text = `${this.template.body.plain.text}{${variable.value}}`;
    },
    addCard() {
      this.template.body.carousel.cards.push(
        {
          title: '',
          description: '',
          image:
            'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
          size: 'TALL',
          buttons: [],
        },
      );
    },
    onFilePicked(event) {
      const files = event.target.files;
      const filename = files[0].name;
      this.file = files[0];

      if (document.querySelector('.drop-img')) {
        document.querySelector('.drop-img').remove();
      }
      if (document.querySelector('.content-inside')) {
        document.querySelector('.content-inside').remove();
      }
      const dropImg = document.createElement('div');
      dropImg.classList.add('drop-img');
      dropImg.classList.add('file-unic');

      const span = document.createElement('span');
      span.classList.add('material-icons-outlined');

      if (files[0].type.includes('image')) {
        span.innerHTML = 'image';
      } else if (files[0].type.includes('video')) {
        span.innerHTML = 'play_circle';
      } else if (files[0].type.includes('audio')) {
        span.innerHTML = 'music_note';
      } else {
        span.innerHTML = 'content_copy';
      }

      const h3 = document.createElement('h3');
      h3.classList.add('h3-type');
      h3.innerHTML = files[0].type;

      dropImg.append(span);
      dropImg.append(h3);
      this.$refs.dropz.append(dropImg);
      this.uploadFile();
    },
    uploadFile() {
      this.isSending = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('account_id', this.account.id);
      formData.append('user_id', this.user.id);
      const config = {
        headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };
      const result = bucketApi.post('', formData, config).then((response) => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: 'Upload realizado com sucesso',
          type: 'success',
        });
        this.progress = 0;
        this.template.body.plain.url = response.data.path;
      }, (error) => {
        this.content = error;
        this.progress = 0;
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: error.response.data.message,
          type: 'danger',
        });
      });
      this.isSending = false;
    },
    removeButton(card, i) {
      card.buttons.splice(i, 1);
    },
    removeCard(i) {
      if (this.template.body.carousel.cards.length > 2) {
        this.template.body.carousel.cards.splice(i, 1);
      }
    },
    changeDate(date, button) {
      if (date) {
        console.log(date);
        button.start_time = moment(date[0]).startOf('day').format();
        button.end_time = moment(date[1]).endOf('day').format();
      }
    },
    addButton(card, type) {
      card.buttons.push({
        text: 'text',
        type,
        start_time: moment().startOf('day').format(),
        end_time: moment().endOf('day').format(),
      });
      this.$emit('input', this.buttons);
    },
    btnAddRich() {
      this.template.content.richCard.suggestions.push({
        reply: {
          text: this.$t('generic-str.action'),
          postbackData: 'Ok',
        },
      });
    },
    btnRemoveRich(index) {
      this.template.content.richCard.suggestions.splice(index, 1);
    },
    btnRemoveCRich(index) {
      this.template.content.richCarousels.images.splice(index, 1);
    },
    btnRemoveEvent(index) {
      if (this.template.content.calendar.suggestions.length > 1) {
        this.template.content.calendar.suggestions.splice(index, 1);
      }
    },
    saveTemplate() {
      this.isSending = true;
      // terminar aqui
      const template = this.template;
      if (this.template.id) {
        RCSService.updateTemplate(template)
          .then((response) => {
            this.$root.$emit('template.submit');
            console.log('WhatsAppService.createNewTemplate:', response);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Template atualizado',
              type: 'success',
            });
            this.$router.go(-1);
            this.isSending = false;
          })
          .catch((ex) => {
            this.isSending = false;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
              content: 'Erro interno, por favor, tente novamente',
              type: 'danger',
            });
            console.log(ex.response.data.errors);
          })
          .finally(() => {
            this.isSending = false;
          });
      } else {
        RCSService.createNewTemplate(template)
          .then((response) => {
            this.$root.$emit('template.submit');
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('template-component.created'),
              type: 'success',
            });
            this.$router.go(-1);
            this.isSending = false;
          })
          .catch((ex) => {
            console.log(ex.response);
            this.isSending = false;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${ex.response.status}`,
              content: ex.response.data.message,
              type: 'danger',
            });
            console.log(ex.response.data.errors);
          })
          .finally(() => {
            this.isSending = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* .header-type {
  max-width: 240px;
} */

.content::v-deep {
  .vti__dropdown {
    background: #fff;
  }
}

.category {
  color: #0d0d0d;

  .col-2 {
    text-align: center;

    &>i {
      font-size: 24pt;
    }
  }

  .col-10>h3,
  .col-2>i {
    color: inherit !important;
  }
}

.preview {
  border-radius: 0.2rem;
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
  position: relative;

  &::before {
    background: url('/assets/img/chat-bg.png');
    background-size: 366px 666px;
    content: '';
    opacity: 0.06;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .preview-sample {
    max-width: 340px;
    position: sticky;
    top: 92px;
  }

  .preview-message,
  .preview-btns .btn {
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 0.32rem;
    width: 100%;
  }

  .preview-btns {
    margin: 0 !important;

    .btn {
      border: none;
      min-height: 32px;
      width: calc(50% - 0.25rem);
      margin: 0.25rem 0.25rem 0.5rem 0;

      &:nth-child(2) {
        margin: 0.25rem 0 0.5rem 0.25rem;
      }

      &.last {
        margin: 0.25rem 0 0.25rem 0;
        width: 100%;
      }
    }
  }

  .preview-message {
    border-top-left-radius: 0;
    padding: 0.25rem 0.25rem 0 0.25rem;
    position: relative;
    z-index: 2;
    min-height: 32px;

    &>header,
    &>div,
    &>footer {
      margin-bottom: 0.25rem;
      padding: 0.25rem;
    }

    &>time {
      position: absolute;
      bottom: 0.25rem;
      right: 0.5rem;
      font-size: 80%;
    }

    &>footer {
      color: rgb(140, 140, 140);
    }

    &>header {
      overflow: hiddden;
      max-height: 280px;
      max-height: 100%;
      font-weight: bold;

      .media {
        min-height: 100px;
        border-radius: 0.32rem;
        background: #ececec;
        margin-bottom: 0.2rem;
        overflow: hidden;

        &>img,
        &>object,
        &>video {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

/* Rebranding */
.body-section {
  position: relative;
}

.input-info {
  position: absolute;
  right: 20px;
  top: unset;
  color: #aaa;
  bottom: -30px;
}

.input-info.text-area {
  top: 270px;
}

.new-template-whats {
  padding: 15px 15px 33px;
  margin: 0 auto;
  background: var(--background-2);
  margin: 10px;
}

.new-template-title {
  color: rgb(28, 30, 33);
}

.darkmode .new-template-title,
.darkmode .new-template-whats p span {
  color: #fff;
}

.new-template-whats p span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: left;
  color: rgb(96, 103, 112);
}

.carousel-visible {
  position: sticky;
  top: 100px;
}

.carousel-template .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep {
  .carousel-item[role='listitem'] {
    margin-bottom: 1.2rem !important;

    &>img {
      display: none !important;
    }
  }

  .carousel-caption {
    position: static !important;

    &>p {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.carousel-template::v-deep .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep .carousel-control-next-icon,
.carousel-template::v-deep .carousel-control-prev-icon {
  width: 100%;
  height: 45px;
  position: relative;
}

.carousel-template::v-deep .carousel-control-next-icon::after,
.carousel-template::v-deep .carousel-control-prev-icon::after {
  color: var(--clr-yup-purple);
  font-weight: 400;
  font-family: 'Material Icons';
  right: 0;
  top: 10px;
  width: 100%;
}

.carousel-template::v-deep .carousel-control-next-icon::after {
  content: '\e5e1' !important;
}

.carousel-template::v-deep .carousel-control-prev-icon::after {
  content: '\e2ea';
}

.carousel-indicators {
  bottom: -1.5rem;
}

.icon-template span {
  color: var(--clr-yup-purple);
  font-size: 122px;
}

.new-template-whats {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.optional-template-input {
  display: block;
  float: left;
  font-size: 12px;
  background: #f4f4f4;
  padding: 5px;
  border-radius: 10px;
  transform: translateY(-8px) translateX(-5px) scale(0.8);
}

.darkmode .optional-template-input {
  background: var(--background-3);
}

.button-icon {
  height: fit-content;
  padding: 0;
}

.media-icon {
  height: fit-content;
  font-size: 1.75rem;
  transition: 0.5s;
  padding: 9px;
  cursor: pointer;
}

.media-icon.active {
  color: var(--clr-yup-purple);
}

.media-icon:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

::v-deep .body-section .textarea-information {
  display: none;
}

::v-deep body {
  background-color: #fff;
}

.create-template-section {
  background: transparent;
  box-shadow: unset;
}

/* Phone preview */
.preview-div {
  display: flex;
  position: sticky;
  /* align-items: center; */
  height: fit-content;
  top: 100px;
}

.preview-wrapper {
  width: 100%;
  // height: fit-content; // Position-sticky
  // height: 512px;
  z-index: 1;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.preview-btns .btn {
  color: var(--foreground);
}

.btn.last {
  width: 100%;
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }

  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }

  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
    flex-wrap: wrap;
  }

  .card-preview {
    // position: absolute; // Position-sticky
    position: relative;
    width: 410px;
    // top: 200px; // Position-sticky
    // right: 20%; // Position-sticky
    right: 170px;
    margin-left: auto;
    margin-bottom: 1rem;
    min-height: 93px;
    --gap: 0.5rem;
  }

  .card-preview .card-body {
    padding: 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;

    video {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    img,
    object {
      margin-left: auto;
      margin-right: auto;
    }

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.rebranding .card.card-preview .card-text.footer-item {
  color: var(--gray-font-color);
  margin-bottom: 0;
}

.card-preview:first-of-type {
  margin-top: calc(10 * var(--gap));
}

.card-preview:last-of-type {
  margin-bottom: calc(35 * var(--gap));
}

.card-preview:last-of-type .card-body {
  padding: 1rem;
}

.preview-btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sort-group,
.create-group {
  width: auto;
}

.card-btn-action {
  background-color: var(--background-3);
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 768px) and (max-width: 1650px) {
  .card-preview {
    margin-right: auto;
    margin-left: -20px;
  }
}

/* Refact */
.left-content-column {
  >h2 {
    font-size: 1rem;
    margin-bottom: 0;

    &~span {
      font-size: .75rem;
    }
  }

  .form-group {
    label {
      font-size: 1rem;

      >span {
        color: var(--gray-font-color-3);
      }
    }

    small {
      // margin-left: auto;
      text-align: right;
      color: var(--gray-font-color-3) !important;
    }
  }
}

.accordion-wpp-template {
  margin-top: .5rem;
  margin-bottom: 2rem;

  .card {
    background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: .5rem 1rem;
      background-color: #F2F4F7;
      border-color: #E5E5E5;
    }
  }

  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: .5rem;
    position: relative;
    z-index: 1;

    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }

    .btn-template {
      display: flex;
      height: auto;
      padding: 0;
      font-weight: 400;

      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;

        p.title-opt {
          font-size: .875rem;
          margin-bottom: .25rem;
        }

        span {
          white-space: break-spaces;
          font-size: .75rem;
          color: #595959;
        }
      }
    }

    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;

      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.header-wrapper {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .card {
    // background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    width: 107px;
    height: 147px;

    /* &.card:not(:first-child) .card-header {
      padding: .85rem .5rem;
    } */
    .card-header {
      height: 100%;
      padding: .85rem .5rem;
      transition: .5s;
      box-shadow: 0px 0px 0px 0px #ffffff00;

      img {
        width: 100%;
        height: 61px;
        object-fit: none;
        object-position: 50% 0px;
      }

      &.active {
        box-shadow: 0px 0px 0px 1px var(--clr-yup-purple);
      }
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F2F2F2;
      width: 100%;
      height: 61px;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }
    }

    .btn-choose-type {
      height: fit-content;
      padding: 0;
      font-weight: 400;
      font-size: .75rem;
    }
  }
}

.body-example-param {
  display: flex;
  flex-direction: column;

  >.badge {
    width: fit-content;
    margin-bottom: .5rem;
    margin-left: auto;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
}

/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}
</style>
<style>
body {
  background-color: var(--background-3);
}

/* body.darkmode {
  background-color: #ffffff !important;
} */
.info-var {
  color: #16CD08;
}

.info-var-tip {
  color: #6B23DC;
}

.info-var-tip-text {
  color: #AAA;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
}

.var-name-span {
  color: #AD1519;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.hr-divider {
  border: solid 1px #E4E7EC;
  width: 96%;
}

.content-upload {
  height: 200px;
  width: 100%;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  text-align: center;
  align-content: center;
}

.content-upload:hover {
  border: dashed 2px #ccc;
}

.content-upload span {
  color: #5A99EC;
  font-size: 50px;
}

.content-upload h4 {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}

.link-input {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #667085;
}

.card-rich-cover {
  border-radius: 8px;
  overflow: hidden;
}

.shadow-card {
  width: 300px;
  box-shadow: 1px 2px 3px 1px #0000000f;
  padding: 10px;
  border: solid #e7e7e7 1px;
}

.shadow-card label {
  color: #0D0519;
  font-weight: 400;
  font-size: 13px;
  margin-top: 5px;
}

.btn-add-action {
  width: 100%;
  background: #fff;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px;
  align-content: center;
  color: #101828;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.btn-add-action span {
  font-size: 16px;
  color: #101828;
}

.btn-add-action-hover {
  width: 100%;
  background: #fff;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px;
  align-content: center;
  color: #101828;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  display: none;
}

.btn-add-action-hover span {
  flex: 1;
  font-size: 19px;
}

.btn-add-action-hover span:nth-child(1) {
  color: #752DE6 !important;
}

.btn-add-action-hover span:nth-child(3) {
  color: #0099FF !important;
}

.btn-add-action-hover span:nth-child(4) {
  color: #870E1E !important;
}

.btn-add-action-hover span:nth-child(5) {
  color: #16870E !important;
}

.btn-actions:hover .btn-add-action-hover {
  display: flex;
}

.btn-actions:hover .btn-add-action {
  display: none;
}

.content-upload {
  cursor: pointer;
}

.content-upload input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-unic {
  float: none !important;
  margin: 0 auto !important;
}

.btn-add-template {
  background: none !important;
  border: none !important;
  color: var(--clr-yup-purple) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: flex !important;
  padding: 0px !important;
  flex-direction: row-reverse;
  align-self: flex-end;
  flex: 1;
}

.content-message {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
}

::v-deep .card-edit-btn {
  margin: 10px;
  color: #fff;
  cursor: pointer;
  left: 0;
}

::v-deep .card-edit-btn i {
  color: #fff !important;
}

::v-deep .card-edit-btn:hover {
  color: rgb(230, 230, 230);
}

.cropper {
  position: absolute;
  top: 10px;
  right: 30px;
}

.remove-card-icon {
  position: absolute;
  color: #fff;
  right: 35px;
  top: 7px;
}

::v-deep .rebranding .vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
  width: 70%;
}

::v-deep .vue-tel-input .form-control {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
